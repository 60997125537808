import React from 'react';
import { Link } from 'gatsby';

// components
import Layout from '../components/layout';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons';

const IndexPage = () => {
  return (
    <Layout>
      <div className="container__box">
        <h1><span class="heading__naples">Usługi</span> informatyczne</h1>
        <p>Szybko, profesjonalnie, w studenckich cenach <FontAwesomeIcon icon={faAward} className="ml-1"/></p>
      </div>
      <div className="container__graybox">
        <h2>Naprawa laptopów Łódź i okolice</h2>
        <p>Serwis na terenie Łodzi i okolic, zawodowo zajmujemy się naprawą sprzętu komputerowego. Każda z usług jest realizowana jak najszybciej oraz za przystępne pieniądze.</p>
      </div>
      <div className="container__box">
        <h2>Usługi na miejscu</h2>
        <p>Jeżeli jest to możliwe, dojeżdżamy do klienta i usuwamy usterkę na miejscu. Ostatecznie zabieramy sprzęt, naprawiamy i dostarczamy skąd został odebrany.</p>
      </div>
      <div className="container__graybox">
        <h2>Strony internetowe</h2>
        <p>Tanio przygotujemy dla Ciebie stronę internetową, wystawimy ją w sieci, dodatkowo w pełni wspierając przez roczny okres jej funkcjonowanie. Płacisz tylko za domenę oraz usługę stworzenia witryny.</p>
        <p>Zapraszamy do <Link to="/contact">kontaktu</Link> w celu wyjaśnienia szczegółów.</p>
      </div>
      <div className="container__box">
        <h2>Dołącz do grona zadowolonych klientów</h2>
        <p>Działamy błyskawicznie, przekonaj się, że czas realizacji zlecenia klienta przekroczy Twoje oczekiwania. Specjalizujemy się w profesjonalnej naprawie laptopów, notebooków komputerów stacjonarnych.</p>
      </div>
      <div className="container__graybox">
        <h2>Pogotowie komputerowe w szerokim zakresie</h2>
        <p>Świadczymy usługi w Łodzi, Zgierzu oraz Strykowie, w tych miastach odbierzemy od Ciebie sprzęt. Realizujemy również bezpłatne dostawy sprzętu po wykonanych usługach.</p>
      </div>
      <div className="container__box">
        <h2>Skrzynka pocztowa dla Twojej firmy</h2>
        <p>W przystępnej cenie utworzymy skrzynkę pocztową z Twoją domeną, dla Twojej firmy. </p>
      </div>
      <div className="container__graybox">
        <h2>Gwarancja</h2>
        <p>Zapewniamy gwarancję na każdą z wykonanych usług.</p>
      </div>
    </Layout>
  )
}

export default IndexPage;